import { fromJS } from 'immutable';

import { toCurrency } from 'util/currency';

export const getTipOutsRows = state =>
  state.getIn(['calculateTipOutsDrawer', 'tipOutsData', 'tips_by_job']);
export const getTipOutsRuns = state =>
  state.getIn(['calculateTipOutsDrawer', 'tipOutsData', 'runs']);
export const getTipInsData = state =>
  state.getIn(['calculateTipOutsDrawer', 'tipInsData']);
export const getTipInsByDayData = state =>
  state.getIn(['calculateTipOutsDrawer', 'tipInsData', 'tips_by_day']).toJS();
export const getTipInsTotal = state =>
  state.getIn(['calculateTipOutsDrawer', 'tipInsData', 'total_tips']);

export const getTipOutsBreakdown = state =>
  fromJS({
    pay_period: state.getIn([
      'calculateTipOutsDrawer',
      'tipOutsData',
      'pay_period',
    ]),
    total_tips: toCurrency(
      state.getIn(['calculateTipOutsDrawer', 'tipOutsData', 'total_tips'])
    ),
    hourly_rate: toCurrency(
      state.getIn(['calculateTipOutsDrawer', 'tipOutsData', 'hourly_rate'])
    ),
    total_hours_worked: state.getIn([
      'calculateTipOutsDrawer',
      'tipOutsData',
      'total_hours',
    ]),
  });
export const getTipOutsPayPeriod = state =>
  state.getIn(['calculateTipOutsDrawer', 'tipOutsData', 'pay_period']);
export const getTipOutsTotal = state =>
  state.getIn(['calculateTipOutsDrawer', 'tipOutsData', 'total_tips']);
export const getTipOutsFooterTotals = state =>
  fromJS({
    tips: toCurrency(
      state.getIn(['calculateTipOutsDrawer', 'tipOutsData', 'total_tips'])
    ),
    hours_worked: state.getIn([
      'calculateTipOutsDrawer',
      'tipOutsData',
      'total_hours',
    ]),
  });

export const getSelectedPayPeriod = state =>
  state.getIn(['calculateTipOutsDrawer', 'selectedPayPeriod']);
export const getTipOutsError = state =>
  state.getIn(['calculateTipOutsDrawer', 'tipOutsData', 'errors']);
export const getTipInsError = state =>
  state.getIn(['calculateTipOutsDrawer', 'tipInsData', 'errors']);
export const getTipInsLoading = state =>
  state.getIn(['calculateTipOutsDrawer', 'tipInsLoading']);
export const getTipOutsLoading = state =>
  state.getIn(['calculateTipOutsDrawer', 'tipOutsLoading']);
export const getCurrentLocationPayrollDetails = state => {
  const location = state.getIn(['session', 'currentLocation']);
  return {
    payrollInfo: location.get('payroll_info').toArray(),
    payrollPeriod: location.get('payroll_period'),
    startOfWorkWeek: location.get('start_of_week'),
    twiceWeekPayrollInfo: state.getIn([
      'tipPoolingPolicies',
      'twiceWeekPayrollInfo',
    ]),
  };
};
