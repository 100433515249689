import { createSelector } from 'reselect';

import {
  getCompanyPayrollState,
  getCurrentCompanyAge,
  getCurrentLocation,
  getCurrentLocationTierId,
  getCurrentUserIsOwner,
  getGhostPackagingManagePlanPageExperimentEnabled,
  getHasActiveTrialPeriod,
  getIsVideoAskEnabled,
  getManagePlanPagePayrollPromoEOYRolloutEnabled,
  getStarterKitPayrollPackage,
  getStarterPackageEnabled,
} from 'selectors/session';

import { selectIsSubscribedAnnually } from 'features/biller/selectors';
import {
  selectGhostEmployeeCapV1ExperimentEnabled,
  selectGhostEmployeeCapV2ExperimentEnabled,
} from 'features/enforcement/EnforcementBanner/selectors';
import { EOY_2024_PAYROLL_PACKAGE } from 'features/managePlan/constants';
import { planCardCtaType } from 'features/managePlan/util';
import { ACTIVE_PAYROLL, CHURNED_PAYROLL } from 'features/payroll/constants';
import {
  getIsFetchingPayrollInfo,
  getPayrollPackage,
} from 'features/payroll/PayrollInfo/PayrollInfoSlice';

import {
  selectHasMoreThan20Employees,
  selectIsCurrentLocationTierBasic,
} from '../enforcement/EnforcementBanner/selectors';

export const selectAllProducts = state => state.get('managePlan');

export const selectFeatures = state => state.get('managePlan').features;

export const selectIsSelectedBillingCycleAnnual = state =>
  state.get('managePlan').isSelectedBillingCycleAnnual;

export const selectLocationInNonPayingUnsecuredTrial = state =>
  state.getIn(['session', 'monetization', 'locationInNonPayingUnsecuredTrial']);

export const selectLocationIsOnStarterPayrollPackage = createSelector(
  getStarterPackageEnabled,
  getStarterKitPayrollPackage,
  (starterPackageEnabled, starterKitPayrollPackage) =>
    starterPackageEnabled && Boolean(starterKitPayrollPackage)
);

export const selectGhostEmployeeExperimentsEnabled = createSelector(
  getGhostPackagingManagePlanPageExperimentEnabled,
  selectGhostEmployeeCapV1ExperimentEnabled,
  selectGhostEmployeeCapV2ExperimentEnabled,
  (
    ghostPackagingManagePlanPageExperimentEnabled,
    ghostEmployeeCapV1ExperimentEnabled,
    ghostEmployeeCapV2ExperimentEnabled
  ) =>
    ghostPackagingManagePlanPageExperimentEnabled ||
    ghostEmployeeCapV1ExperimentEnabled ||
    ghostEmployeeCapV2ExperimentEnabled
);

export const shouldSeeVideoAsk = createSelector(
  getCurrentLocationTierId,
  getCurrentUserIsOwner,
  getHasActiveTrialPeriod,
  getCurrentCompanyAge,
  getIsVideoAskEnabled,
  (
    currentLocationTierId,
    isOwner,
    hasActiveTrialPeriod,
    currentCompanyAge,
    isVideoAskEnabled
  ) =>
    isOwner &&
    (hasActiveTrialPeriod ||
      (!hasActiveTrialPeriod && currentLocationTierId === 1)) &&
    currentCompanyAge < 18 &&
    isVideoAskEnabled
);

export const selectShouldShowEnforcementBottomSheet = createSelector(
  selectGhostEmployeeExperimentsEnabled,
  selectHasMoreThan20Employees,
  selectIsCurrentLocationTierBasic,
  (
    ghostEmployeeExperimentsEnabled,
    hasMoreThan20Employees,
    isCurrentLocationTierBasic
  ) =>
    ghostEmployeeExperimentsEnabled &&
    hasMoreThan20Employees &&
    !isCurrentLocationTierBasic
);

export const selectPlanCardCta = createSelector(
  (_, props) => props.tierId,
  (_, props) => props.tierName,
  getCurrentLocationTierId,
  selectIsSubscribedAnnually,
  selectIsSelectedBillingCycleAnnual,
  getHasActiveTrialPeriod,
  selectLocationIsOnStarterPayrollPackage,
  (
    tierId,
    tierName,
    currentLocationTierId,
    isSubscribedAnnually,
    isSelectedBillingCycleAnnual,
    hasActiveTrialPeriod,
    locationIsOnStarterPayrollPackage
  ) =>
    planCardCtaType({
      tierId,
      tierName,
      isSubscribedAnnually,
      hasActiveTrialPeriod,
      currentLocationTierId,
      isSelectedBillingCycleAnnual,
      locationIsOnStarterPayrollPackage,
    })
);

export const selectProducts = createSelector(
  selectAllProducts,
  selectIsSelectedBillingCycleAnnual,
  (products, isSelectedBillingCycleAnnual) => {
    const { monthlyProducts, annualProducts } = products;

    return isSelectedBillingCycleAnnual ? annualProducts : monthlyProducts;
  }
);

export const selectHasAnnualProducts = createSelector(
  selectAllProducts,
  ({ annualProducts }) => !!annualProducts.length
);

export const selectIsUSCustomer = createSelector(
  getCurrentLocation,
  currentLocation => currentLocation.get('country_code') === 'US'
);

export const selectProductTipManager = state =>
  state.get('managePlan').tipManager;
export const selectProductTipManagerPrice = state =>
  state.get('managePlan').tipManager.price;

export const selectProductTaskManager = state =>
  state.get('managePlan').taskManager;
export const selectProductTaskManagerPrice = state =>
  state.get('managePlan').taskManager.price;

export const selectDisplayPayrolPromoSection = createSelector(
  getManagePlanPagePayrollPromoEOYRolloutEnabled,
  selectLocationIsOnStarterPayrollPackage,
  selectIsUSCustomer,
  getPayrollPackage,
  getCompanyPayrollState,
  (
    payrollPromoEOYRolloutEnabled,
    locationIsOnNBKPayrollPackage,
    isUSCustomer,
    payrollPackage,
    payrollState
  ) =>
    payrollPromoEOYRolloutEnabled &&
    isUSCustomer &&
    !locationIsOnNBKPayrollPackage &&
    payrollPackage.name === EOY_2024_PAYROLL_PACKAGE &&
    ![CHURNED_PAYROLL, ACTIVE_PAYROLL].includes(payrollState)
);

export const selectIsPageDataLoaded = createSelector(
  selectFeatures,
  getPayrollPackage,
  getIsFetchingPayrollInfo,
  (features, payrollPackage, isFetchingPayrollInfo) =>
    Boolean(features.length) && payrollPackage && !isFetchingPayrollInfo
);
